import React from 'react';
import { Card, Form, Table } from 'react-bootstrap';
import useForm from '../../hooks/useForm';
import IntegerInput from '../../components/IntegerInput';

const MDA = () => {
  const {
    data: { mda },
    update
  } = useForm();

  const handleFormsChange = val => update('mda', { ...mda, forms: val });
  const handleItemsChange = val => update('mda', { ...mda, items: val });

  return (
    <Card>
      <Card.Header>Substance Misuse (MDA)</Card.Header>
      <Card.Body>
        <Table borderless>
          <tbody>
            <tr style={{ fontWeight: 'bold' }}>
              <td>Forms</td>
              <td>Items</td>
            </tr>
            <tr>
              <td>
                <Form.Control as={IntegerInput} value={mda.forms} onChange={handleFormsChange} />
              </td>
              <td>
                <Form.Control as={IntegerInput} value={mda.items} onChange={handleItemsChange} />
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default MDA;
