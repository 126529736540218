export default {
  tillReading: {},
  vatAnalysis: {},
  actualTakings: {
    cash: {},
    cheques: [],
    cards: [],
    coupons: [],
    payouts: [],
    notes: ''
  }
};
