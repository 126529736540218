import React, { createContext, useContext } from 'react';
import useLocalStorage from './useLocalStorage';

const FormContext = createContext();

export const FormProvider = ({ children, formName, initialValues = {} }) => {
  const [data, setData] = useLocalStorage(`${formName}_form`, initialValues);
  const update = (key, value) => setData({ ...data, [key]: value });
  const reset = () => setData(initialValues);

  return <FormContext.Provider value={{ data, update, reset }}>{children}</FormContext.Provider>;
};

export default () => useContext(FormContext);
