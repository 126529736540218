import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Days from './Days';
import Dates from './Dates';

const Schedule = ({ style = {} }) => {
  return (
    <Card style={style}>
      <Card.Header>Schedule</Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Days />
          </Col>
          <Col>
            <Dates />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Schedule;
