import moment from 'moment-timezone';
import React from 'react';
import {
  Container,
  Breadcrumb,
  InputGroup,
  Button,
  FormControl,
  Modal,
  Row,
  Col,
  Form,
  Card,
  Nav,
  Table
} from 'react-bootstrap';
import useBranch from './useBranch';
import DecimalInput from '../../components/DecimalInput';
import IntegerInput from '../../components/IntegerInput';

const Tills = ({ style }) => {
  const { tills, addTill, updateTill, saving } = useBranch();

  return (
    <Card style={style}>
      <Card.Header>Tills</Card.Header>
      <Card.Body>
        <Table>
          <thead>
            <tr style={{ background: '#343a40', color: 'white', fontWeight: 'bold' }}>
              <td>Number</td>
              <td>Float</td>
              <td>Reference</td>
              <td>Setup Date</td>
              <td />
            </tr>
          </thead>
          <tbody>
            {tills.map(till => (
              <tr key={till.id}>
                <td>
                  <Form.Control
                    as={IntegerInput}
                    value={till.tillNumber}
                    onBlur={val => updateTill(till.id, { tillNumber: String(val) })}
                  />
                </td>

                <td>
                  <Form.Control
                    as={DecimalInput}
                    value={till.tillFloat}
                    onChange={val => updateTill(till.id, { tillFloat: val })}
                  />
                </td>
                <td>
                  <Form.Control
                    defaultValue={till.reference}
                    onBlur={e => updateTill(till.id, { reference: e.target.value })}
                  />
                </td>
                <td>{moment(till.setupDate).format('DD/MM/YYYY')}</td>
                <td>
                  <Nav.Link
                    style={{ padding: 0, textAlign: 'right' }}
                    onClick={() => {
                      console.log('deactivate');
                    }}
                    disabled={saving}
                  >
                    deactivate
                  </Nav.Link>
                </td>
              </tr>
            ))}
            <tr>
              <td style={{ borderTop: 0 }}>
                <Nav.Link
                  style={{ padding: 0 }}
                  onClick={() => {
                    const nTillNumber = tills.map(t => t.tillNumber).reduce((a, b) => Math.max(a, b), 0) + 1;
                    addTill({ tillFloat: 50, tillNumber: nTillNumber, reference: '' });
                  }}
                  disabled={saving}
                >
                  add new till
                </Nav.Link>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default Tills;
