export default {
  pound_50: 50,
  pound_20: 20,
  pound_10: 10,
  pound_5: 5,
  pound_2: 2,
  pound_1: 1,
  penny_50: 0.5,
  penny_20: 0.2,
  penny_10: 0.1,
  penny_5: 0.05,
  penny_2: 0.02,
  penny_1: 0.01
};
