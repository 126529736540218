import moment from 'moment';
import React, { createContext, useContext, useEffect } from 'react';
import axios from 'axios';
import useLocalStorage from './useLocalStorage';
import { apiBaseUrl } from '../config';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useLocalStorage('ph_session');

  useEffect(() => {
    if (session) {
      const in5Mins = moment
        .utc()
        .add(5, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss');
      if (session.expiry < in5Mins) setSession(null);
    }
  }, []);

  const signIn = async (email, password) => {
    try {
      const { data } = await axios({
        method: 'POST',
        url: `${apiBaseUrl}/sessions/start`,
        data: { email, password }
      });
      setSession(data);
    } catch (err) {
      console.log(err);
    }
  };
  const signOut = () => setSession(null);

  setInterval(() => {
    if (session) {
      const in5Mins = moment
        .utc()
        .add(5, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss');
      if (session.expiry < in5Mins) setSession(null);
    }
  }, 5000);

  return <AuthContext.Provider value={{ session, signIn, signOut, setSession }}>{children}</AuthContext.Provider>;
};

export default () => useContext(AuthContext);
