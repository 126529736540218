import moment from 'moment';
import React from 'react';
import { Container, Breadcrumb, Button, Card, Table } from 'react-bootstrap';
import Spinner from '../../components/Spinner';
import useEndpoint from '../../hooks/useEndpoint';
import useAuth from '../../hooks/useAuth';
import useHistory from '../../hooks/useHistory';

const MissedSubmission = ({ date }) => {
  const history = useHistory();

  const dateText = moment(date).format('dddd Do MMMM YYYY');
  const handleClick = () => history.push(`session?date=${date}`);

  return (
    <tr>
      <td>
        <b>{dateText}</b>
      </td>
      <td>
        <Button onClick={handleClick}>Enter Now</Button>
      </td>
    </tr>
  );
};

const Missed = () => {
  const { session } = useAuth();
  const [res] = useEndpoint({
    method: 'GET',
    url: `nhsSessions/missedSessions?branchId=${session.branchId}`
  });

  return (
    <Container fluid style={{ paddingTop: 15 }}>
      <Breadcrumb>
        <Breadcrumb.Item>NHS</Breadcrumb.Item>
        <Breadcrumb.Item active>Missed Submissions</Breadcrumb.Item>
      </Breadcrumb>
      {!res.complete ? (
        <Spinner />
      ) : (
        <Card>
          <Card.Header>Missing NHS Data Submissions</Card.Header>
          <Card.Body>
            The following daily NHS submissions have not been completed. You must back-fill data for these dates before
            access is restored for todays submission.
            <Table style={{ marginTop: '2rem' }}>
              <thead>
                <tr style={{ background: '#343a40' }}>
                  <td style={{ color: 'white' }}>
                    <b>Date</b>
                  </td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {res.data.map(date => (
                  <MissedSubmission key={date} date={date} />
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};

export default Missed;
