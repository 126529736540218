import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import useForm from '../../hooks/useForm';

const Summary = ({ style }) => {
  const { data } = useForm();
  const [stats, setStats] = useState({
    totalPaperForms: 0,
    totalPaperItems: 0,
    totalETPForms: 0,
    totalETPItems: 0,
    totalForms: 0,
    totalItems: 0
  });

  useEffect(() => {
    const totalPaperForms =
      (data.paper.exempt.forms || 0) +
      (data.paper.paid.forms || 0) +
      (data.paper.mds.forms || 0) +
      data.paper.careHomes.map(cH => cH.forms).reduce((a, b) => a + b, 0);
    const totalPaperItems =
      (data.paper.exempt.items || 0) +
      (data.paper.paid.items || 0) +
      (data.paper.mds.items || 0) +
      data.paper.careHomes.map(cH => cH.items).reduce((a, b) => a + b, 0);

    const totalETPForms =
      (data.etp.exempt.forms || 0) +
      (data.etp.paid.forms || 0) +
      (data.etp.mds.forms || 0) +
      data.etp.careHomes.map(cH => cH.forms).reduce((a, b) => a + b, 0);
    const totalETPItems =
      (data.etp.exempt.items || 0) +
      (data.etp.paid.items || 0) +
      (data.etp.mds.items || 0) +
      data.etp.careHomes.map(cH => cH.items).reduce((a, b) => a + b, 0);

    const totalForms = totalPaperForms + totalETPForms + (data.mda.forms || 0);
    const totalItems = totalPaperItems + totalETPItems + (data.mda.items || 0);

    setStats({
      totalPaperForms,
      totalPaperItems,
      totalETPForms,
      totalETPItems,
      totalForms,
      totalItems
    });
  }, [data]);

  const cardStyle = { overflow: 'hidden', height: '100%', textAlign: 'center' };

  return (
    <Row>
      <Col xl={2} md={4} sm={6} style={{ marginBottom: '1rem' }}>
        <Card bg="success" text="white" style={cardStyle}>
          <Card.Body>
            <b>Total Paper Forms</b>
            <br />
            {stats.totalPaperForms}
          </Card.Body>
        </Card>
      </Col>
      <Col xl={2} md={4} sm={6} style={{ marginBottom: '1rem' }}>
        <Card bg="success" text="white" style={cardStyle}>
          <Card.Body>
            <b>Total Paper Items</b>
            <br />
            {stats.totalPaperItems}
          </Card.Body>
        </Card>
      </Col>
      <Col xl={2} md={4} sm={6} style={{ marginBottom: '1rem' }}>
        <Card bg="secondary" text="white" style={cardStyle}>
          <Card.Body>
            <b>Total ETP Forms</b>
            <br />
            {stats.totalETPForms}
          </Card.Body>
        </Card>
      </Col>
      <Col xl={2} md={4} sm={6} style={{ marginBottom: '1rem' }}>
        <Card bg="secondary" text="white" style={cardStyle}>
          <Card.Body>
            <b>Total ETP Items</b>
            <br />
            {stats.totalETPItems}
          </Card.Body>
        </Card>
      </Col>
      <Col xl={2} md={4} sm={6} style={{ marginBottom: '1rem' }}>
        <Card bg="info" text="white" style={cardStyle}>
          <Card.Body>
            <b>Total Forms</b>
            <br />
            {stats.totalForms}
          </Card.Body>
        </Card>
      </Col>
      <Col xl={2} md={4} sm={6} style={{ marginBottom: '1rem' }}>
        <Card bg="info" text="white" style={cardStyle}>
          <Card.Body>
            <b>Total Items</b>
            <br />
            {stats.totalItems}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Summary;
