import React from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import queryString from 'query-string';
import { FormProvider } from '../../hooks/useForm';
import useEndpoint from '../../hooks/useEndpoint';
import useHistory from '../../hooks/useHistory';
import Spinner from '../../components/Spinner';
import Information from './Information';
import TillReading from './TillReading';
import VATAnalysis from './VATAnalysis';
import ActualTakings from './ActualTakings';
import Submit from './Submit';
import initialValues from './initialValues';

const Session = ({ location }) => {
  const { tillId, date } = queryString.parse(location.search);

  const history = useHistory();
  const [res] = useEndpoint({
    method: 'GET',
    url: `tills/${tillId}`
  });

  const handleBreadcrumbClick = () => history.push('/cashing-up');

  return res.complete && !res.error ? (
    <FormProvider formName={`cashing_up_${tillId}_${date}`} initialValues={initialValues}>
      <Container fluid style={{ paddingTop: '1rem' }}>
        <Breadcrumb>
          <Breadcrumb.Item onClick={handleBreadcrumbClick}>Cashing Up</Breadcrumb.Item>
          <Breadcrumb.Item active>Cash Up</Breadcrumb.Item>
        </Breadcrumb>

        <Row>
          <Col>
            <div style={{ marginBottom: '1rem' }}>
              <Information tillNo={res.data.tillNumber} date={date} />
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <TillReading />
            </div>

            <div style={{ marginBottom: '1rem' }}>
              <VATAnalysis />
            </div>
          </Col>
          <Col>
            <div style={{ marginBottom: '1rem' }}>
              <ActualTakings />
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <Submit tillId={tillId} date={date} />
            </div>
          </Col>
        </Row>
      </Container>
    </FormProvider>
  ) : (
    <Spinner />
  );
};

export default Session;
