import React, { useState, useRef } from 'react';
import { InputGroup, Button, FormControl, Modal, Row, Col, Form, Table, Nav } from 'react-bootstrap';
import useEndpoint from '../../../../hooks/useEndpoint';
import DecimalInput from '../../../../components/DecimalInput';

const ModalForm = ({ rows = [], onChange, show, onClose }) => {
  const [amount, setAmount] = useState();
  const selectEl = useRef(null);
  const [res] = useEndpoint({
    method: 'GET',
    url: 'couponTypes'
  });
  const couponTypes = res.data || [];
  const availableCouponTypes = couponTypes.filter(type => !rows.map(r => r.type).includes(type));

  const handleAdd = () => {
    if (isAddDisabled()) return;

    onChange([...rows, { type: selectEl.current.value, amount }]);
    setAmount();
  };
  const handleRemove = index => onChange(rows.filter((_, i) => i !== index));

  const isSelectEmpty = () => !(selectEl.current && selectEl.current.value !== '');
  const isAddDisabled = () => amount === undefined || isSelectEmpty();

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Coupons/Tokens/Vouchers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col style={{ flex: 2 }}>
            Type
            <Form.Control as="select" ref={selectEl}>
              <>
                {availableCouponTypes.map(type => (
                  <option key={type}>{type}</option>
                ))}
              </>
            </Form.Control>
          </Col>
          <Col style={{ flex: 2 }}>
            Amount
            <Form.Control as={DecimalInput} value={amount} onChange={setAmount} />
          </Col>
          <Col style={{ marginTop: 'auto', flex: 1 }}>
            <Button onClick={handleAdd} style={isAddDisabled() ? { opacity: '0.6' } : {}}>
              Add
            </Button>
          </Col>
        </Row>
        {rows.length > 0 && <br />}
        {rows.length > 0 && (
          <Table>
            <thead>
              <tr style={{ background: '#343a40', color: 'white', fontWeight: 'bold' }}>
                <td>Type</td>
                <td>Amount</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => (
                <tr key={i}>
                  <td>{row.type}</td>
                  <td>{row.amount.toFixed(2)}</td>
                  <td>
                    <Nav.Link style={{ padding: '0 10px 0 0', textAlign: 'right' }} onClick={() => handleRemove(i)}>
                      delete
                    </Nav.Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Coupons = ({ value, onChange }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  const renderTotal = () => value.reduce((acc, row) => acc + row.amount, 0).toFixed(2);

  return (
    <InputGroup>
      <ModalForm show={modalOpen} rows={value} onChange={onChange} onClose={toggleModal} />
      <InputGroup.Prepend>
        <Button onClick={() => toggleModal(true)} variant="secondary">
          Enter
        </Button>
      </InputGroup.Prepend>
      <FormControl type={'text'} value={renderTotal()} disabled={true} style={{ textAlign: 'right' }} />
    </InputGroup>
  );
};

export default Coupons;
