import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { HistoryProvider } from './hooks/useHistory';
import Chrome from './components/Chrome';
import CashingUp from './CashingUp';
import Prescriptions from './Prescriptions';
import Banking from './Banking';

const Home = () => <div>Home</div>;

const App = () => {
  const navConfig = [
    {
      label: 'Dashboard',
      eventKey: '/',
      icon: 'fa-columns'
    },
    {
      label: 'Cashing Up',
      eventKey: '/cashing-up',
      icon: 'fa-file-invoice-dollar'
    },
    {
      label: 'Prescriptions',
      eventKey: '/prescriptions',
      icon: 'fa-file-medical'
    },
    {
      label: 'Banking',
      eventKey: '/banking',
      icon: 'fa-coins'
    }
  ];

  return (
    <BrowserRouter>
      <HistoryProvider>
        <Chrome navConfig={navConfig}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/cashing-up" component={CashingUp} />
            <Route path="/prescriptions" component={Prescriptions} />
            <Route path="/banking" component={Banking} />
            <Redirect to="/" />
          </Switch>
        </Chrome>
      </HistoryProvider>
    </BrowserRouter>
  );
};

export default App;
