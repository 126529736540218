import _ from 'lodash';
import React from 'react';
import { Card, Form, Col, Row } from 'react-bootstrap';
import useForm from '../../hooks/useForm';
import DecimalInput from '../../components/DecimalInput';
import IntegerInput from '../../components/IntegerInput';

const schema = {
  zReading: { label: 'Z Reading', type: 'decimal' },
  numVoids: { label: 'No. of Voids', type: 'integer' },
  refunds: { label: 'Refunds (amount)', type: 'decimal' },
  discount: { label: 'Discount', type: 'decimal' },
  numTransactions: { label: 'No. of Transactions', type: 'integer' }
};

const TillReading = () => {
  const {
    data: { tillReading },
    update
  } = useForm();

  const handleChange = (key, value) => update('tillReading', { ...tillReading, [key]: value });

  return (
    <Card>
      <Card.Header>Till Reading</Card.Header>
      <Card.Body>
        {Object.keys(schema).map((key, i) => (
          <span key={key}>
            {i > 0 && <br />}
            <Row>
              <Col>
                <Form.Label>{schema[key].label}</Form.Label>
              </Col>
              <Col>
                {schema[key].type === 'decimal' ? (
                  <Form.Control as={DecimalInput} value={tillReading[key]} onChange={val => handleChange(key, val)} />
                ) : (
                  <Form.Control as={IntegerInput} value={tillReading[key]} onChange={val => handleChange(key, val)} />
                )}
              </Col>
            </Row>
          </span>
        ))}
      </Card.Body>
    </Card>
  );
};

export default TillReading;
