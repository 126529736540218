import moment from 'moment';
import React from 'react';
import { Container, Breadcrumb, Button, Card, Table } from 'react-bootstrap';
import Spinner from '../../components/Spinner';
import useEndpoint from '../../hooks/useEndpoint';
import useAuth from '../../hooks/useAuth';
import useHistory from '../../hooks/useHistory';

const MissedTill = ({ till, date }) => {
  const history = useHistory();

  const dateText = moment(date).format('dddd Do MMMM YYYY');
  const handleClick = () => history.push(`session?tillId=${till.id}&date=${date}`);

  return (
    <tr style={{ fontWeight: 'bold' }}>
      <td>{dateText}</td>
      <td>Till {till.tillNumber}</td>
      <td>
        <Button onClick={handleClick}>Enter Now</Button>
      </td>
    </tr>
  );
};

const Missed = () => {
  const { session } = useAuth();
  const [res] = useEndpoint({
    method: 'GET',
    url: `cashUpSessions/missedSessions?branchId=${session.branchId}`
  });

  return res.complete ? (
    <Container fluid style={{ paddingTop: 15 }}>
      <Breadcrumb>
        <Breadcrumb.Item>Cashing Up</Breadcrumb.Item>
        <Breadcrumb.Item active>Missed Cash Up Sessions</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>Missing Cash-Up Sessions</Card.Header>
        <Card.Body>
          The following till(s) were not cashed up on the days shown. You must back-fill data for these dates before
          access is restored for todays submission.
          <Table style={{ marginTop: '2rem' }}>
            <thead>
              <tr style={{ background: '#343a40', color: 'white', fontWeight: 'bold' }}>
                <td>Date</td>
                <td>Till</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {res.data.map(({ date, till }) => (
                <MissedTill key={till.id + date} till={till} date={date} />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  ) : (
    <Spinner />
  );
};

export default Missed;
