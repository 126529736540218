import { useState, useEffect } from 'react';
import axios from 'axios';
import Promise from 'bluebird';
import useAuth from './useAuth';
import { apiBaseUrl } from '../config';

Promise.config({ cancellation: true });

const useEndpoint = req => {
  const { session } = useAuth();
  const [res, setRes] = useState({ data: null, complete: false, pending: false, error: null });
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    const fetchData = () =>
      new Promise((resolve, _, onCancel) => {
        setRes({ data: null, complete: false, pending: true, error: null });

        const _req = Object.assign({}, req, { url: `${apiBaseUrl}/${req.url}` });
        if (session) _req.headers = { Authorization: `Bearer ${session.token}` };

        const p = Promise.resolve(axios(_req))
          .then(res => {
            setRes({ data: res.data, complete: true, pending: false, error: null });
            resolve();
          })
          .catch(err => {
            setRes({ data: null, complete: true, pending: false, error: err.message });
            resolve();
          });

        onCancel(() => p.cancel());
        return p;
      });

    const p = fetchData();
    return () => p.cancel();
  }, [req.method, req.url, req.data, refreshCounter]);

  const refresh = () => setRefreshCounter(refreshCounter + 1);

  return [res, refresh];
};

export default useEndpoint;
