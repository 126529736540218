import _ from 'lodash';
import React from 'react';
import { Card, Table, Form } from 'react-bootstrap';
import useForm from '../hooks/useForm';
import IntegerInput from '../components/IntegerInput';
import aggregateCash from './aggregateCash';

const fields = ['_50', '_20', '_10', '_5', '_2', '_1'];

const Cash = () => {
  const { data, update } = useForm();

  const handleChange = (field, val) => update(field, val);

  const renderTotal = key => {
    const val = _.isNil(data[key]) ? 0 : data[key];
    const total = val * parseInt(key.slice(1));
    return total.toFixed(2);
  };

  return (
    <Card>
      <Card.Header>Cash to be Banked</Card.Header>
      <Card.Body>
        <Table borderless>
          <tbody>
            <tr>
              <td>Denomination</td>
              <td>Qty</td>
              <td style={{ textAlign: 'right' }}>Total</td>
            </tr>

            {fields.map(field => (
              <tr key={field}>
                <td>{`£${field.slice(1)}`}</td>
                <td>
                  <Form.Control as={IntegerInput} value={data[field]} onChange={val => handleChange(field, val)} />
                </td>
                <td>
                  <Form.Control type="text" value={renderTotal(field)} disabled style={{ textAlign: 'right' }} />
                </td>
              </tr>
            ))}

            <tr>
              <td style={{ fontWeight: 'bold' }}>Total</td>
              <td></td>
              <td>
                <Form.Control
                  disabled={true}
                  style={{ textAlign: 'right' }}
                  type="text"
                  value={aggregateCash(data).toFixed(2)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default Cash;
