import React, { useState, useRef } from 'react';
import { Button, Card, Table, Form, Modal, Nav } from 'react-bootstrap';
import useEndpoint from '../../hooks/useEndpoint';
import useForm from '../../hooks/useForm';
import IntegerInput from '../../components/IntegerInput';

const ModalForm = ({ onAdd, show, onClose }) => {
  const {
    data: {
      etp: { careHomes }
    }
  } = useForm();

  const [forms, setForms] = useState();
  const [items, setItems] = useState();
  const selectEl = useRef(null);
  const [res] = useEndpoint({
    method: 'GET',
    url: 'careHomes'
  });
  const _careHomes = res.data || [];
  const availableCareHomes = _careHomes.filter(_cH => !careHomes.map(cH => cH.careHomeName).includes(_cH.name));

  const handleAdd = () => {
    if (isAddDisabled()) return;

    const careHomeId = selectEl.current.value;
    const careHome = availableCareHomes.filter(cH => cH.id === careHomeId)[0];
    onAdd(careHomeId, careHome.name, forms, items);

    setForms();
    setItems();
  };
  const handleAddAndClose = (...params) => {
    if (isAddDisabled()) return;
    handleAdd(...params);
    onClose();
  };

  const isSelectEmpty = () => !(selectEl.current && selectEl.current.value !== '');
  const isAddDisabled = () => forms === undefined || items === undefined || isSelectEmpty();

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>Care Home ETP</Modal.Header>
      <Modal.Body>
        <Form>
          <Table borderless>
            <tbody>
              <tr>
                <td>Select Care Home</td>
                <td>
                  <Form.Control as="select" ref={selectEl}>
                    {availableCareHomes.map(careHome => (
                      <option key={careHome.id} value={careHome.id}>
                        {careHome.name}
                      </option>
                    ))}
                  </Form.Control>
                </td>
              </tr>
              <tr>
                <td>Forms</td>
                <td>
                  <Form.Control as={IntegerInput} value={forms} onChange={setForms} />
                </td>
              </tr>
              <tr>
                <td>Items</td>
                <td>
                  <Form.Control as={IntegerInput} value={items} onChange={setItems} />
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAddAndClose} style={isAddDisabled() ? { opacity: '0.6' } : {}}>
          Save & Close
        </Button>
        <Button variant="primary" onClick={handleAdd} style={isAddDisabled() ? { opacity: '0.6' } : {}}>
          Save & Add Another
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ETP = () => {
  const {
    data: { etp },
    update
  } = useForm();

  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  const handleChange = (key, val) => {
    const [p1, p2] = key.split('.');
    update('etp', { ...etp, [p1]: { ...etp[p1], [p2]: val } });
  };
  const handleAdd = (careHomeId, careHomeName, forms, items) =>
    update('etp', { ...etp, careHomes: [...etp.careHomes, { careHomeId, careHomeName, forms, items }] });
  const handleRemove = careHomeId =>
    update('etp', { ...etp, careHomes: etp.careHomes.filter(cH => cH.careHomeId !== careHomeId) });

  return (
    <Card>
      <ModalForm onAdd={handleAdd} show={modalOpen} onClose={toggleModal} />

      <Card.Header>ETP</Card.Header>
      <Card.Body>
        <Table borderless>
          <tbody>
            <tr style={{ fontWeight: 'bold' }}>
              <td></td>
              <td>Forms</td>
              <td>Items</td>
            </tr>
            <tr>
              <th>Exempt</th>
              <td>
                <Form.Control
                  as={IntegerInput}
                  value={etp.exempt.forms}
                  onChange={val => handleChange('exempt.forms', val)}
                />
              </td>
              <td>
                <Form.Control
                  as={IntegerInput}
                  value={etp.exempt.items}
                  onChange={val => handleChange('exempt.items', val)}
                />
              </td>
            </tr>

            <tr>
              <th>Paid</th>
              <td>
                <Form.Control
                  as={IntegerInput}
                  value={etp.paid.forms}
                  onChange={val => handleChange('paid.forms', val)}
                />
              </td>
              <td>
                <Form.Control
                  as={IntegerInput}
                  value={etp.paid.items}
                  onChange={val => handleChange('paid.items', val)}
                />
              </td>
            </tr>

            <tr>
              <th>MDS</th>
              <td>
                <Form.Control
                  as={IntegerInput}
                  value={etp.mds.forms}
                  onChange={val => handleChange('mds.forms', val)}
                />
              </td>
              <td>
                <Form.Control
                  as={IntegerInput}
                  value={etp.mds.items}
                  onChange={val => handleChange('mds.items', val)}
                />
              </td>
            </tr>
          </tbody>
        </Table>

        <Button variant="secondary" style={{ marginBottom: '1rem' }} onClick={toggleModal}>
          Add Care Home ETP
        </Button>

        <Table>
          <thead>
            <tr style={{ background: '#343a40', color: 'white', fontWeight: 'bold' }}>
              <td>Care Home</td>
              <td>Forms</td>
              <td>Items</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {etp.careHomes.map((careHome, i) => (
              <tr key={i}>
                <td style={{ fontWeight: 'bold' }}>{careHome.careHomeName}</td>
                <td>{careHome.forms}</td>
                <td>{careHome.items}</td>
                <td>
                  <Nav.Link
                    style={{ padding: '0 10px 0 0', textAlign: 'right' }}
                    onClick={() => handleRemove(careHome.careHomeId)}
                  >
                    delete
                  </Nav.Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ETP;
