import { useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from './useAuth';
import { apiBaseUrl } from '../config';

const useAsyncEndpoint = fn => {
  const { session } = useAuth();

  const [res, setRes] = useState({ data: null, complete: false, pending: false, error: null });
  const [req, setReq] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (!req) return;

      setRes({ data: null, complete: false, pending: true, error: null });

      const _req = Object.assign({}, req, { url: `${apiBaseUrl}/${req.url}` });
      if (session) _req.headers = { Authorization: `Bearer ${session.token}` };

      try {
        const res = await axios(_req);
        setRes({ data: res.data, complete: true, pending: false, error: null });
      } catch (err) {
        setRes({ data: null, complete: true, pending: false, error: err.message });
      }
    };

    fetchData();
  }, [req]);

  return [res, (...args) => setReq(fn(...args))];
};

export default useAsyncEndpoint;
