import React, { createContext, useContext } from 'react';
import { withRouter } from 'react-router-dom';

const HistoryContext = createContext();

export const HistoryProvider = withRouter(({ history, children }) => {
  return <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>;
});

export default () => useContext(HistoryContext);
