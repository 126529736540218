import React, { useState, useEffect } from 'react';

const IntegerInput = ({ value, onChange, onBlur }) => {
  const [val, setVal] = useState(value !== undefined ? value.toString() : '');

  const handleChange = e => {
    if (e.target.value === '') {
      setVal('');
      onChange();
    } else if (parseInt(e.target.value) < 0) {
      setVal('0');
      onChange(0);
    } else {
      setVal(parseInt(e.target.value).toString());
      onChange(parseInt(e.target.value));
    }
  };

  const handleBlur = () => onBlur(parseInt(val));

  useEffect(() => {
    setVal(value !== undefined ? value.toString() : '');
  }, [value]);

  return <input className="form-control" type="number" value={val} onChange={handleChange} onBlur={handleBlur} />;
};

IntegerInput.defaultProps = {
  onChange: () => {},
  onBlur: () => {}
};

export default IntegerInput;
