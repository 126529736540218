import React from 'react';
import { Container, Row, Col, Breadcrumb, Button, Card, Table, Form } from 'react-bootstrap';
import { FormProvider } from '../hooks/useForm';
import useAuth from '../hooks/useAuth';
import useHistory from '../hooks/useHistory';
import useForm from '../hooks/useForm';
import useEndpoint from '../hooks/useEndpoint';
import Spinner from '../components/Spinner';
import aggregateCash from './aggregateCash';
import Cash from './Cash';
import Submit from './Submit';
import RecentBanking from './RecentBanking';

const Inner = ({ branch, refresh }) => {
  const history = useHistory();
  const { data } = useForm();

  const handleBreadcrumbClick = () => history.push('/banking');
  const isValid = () => aggregateCash(data) <= branch.safeTakings;

  return (
    <Container fluid style={{ paddingTop: '1rem' }}>
      <Breadcrumb>
        <Breadcrumb.Item onClick={handleBreadcrumbClick}>Banking</Breadcrumb.Item>
        <Breadcrumb.Item active>Banking</Breadcrumb.Item>
      </Breadcrumb>

      <Row style={{ marginTop: '1rem' }}>
        <Col md={6} lg={6} sm={6}>
          <Cash />
        </Col>

        <Col md={6} lg={6} sm={6}>
          <Card>
            <Card.Header>Available Cash</Card.Header>
            <Card.Body>
              Total cash in safe:
              <h3>£{branch.safeTakings.toFixed(2)}</h3>
            </Card.Body>
          </Card>

          <RecentBanking style={{ marginTop: '1rem' }} />
        </Col>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <Col md={6}>
          <Submit disabled={!isValid()} onSubmitted={refresh} />
        </Col>
      </Row>
    </Container>
  );
};

const Banking = () => {
  const { session } = useAuth();
  const [res, refresh] = useEndpoint({
    method: 'GET',
    url: `branches/${session.branchId}`
  });

  return res.complete && !res.error ? (
    <FormProvider formName={'banking'}>
      <Inner branch={res.data} refresh={refresh} />
    </FormProvider>
  ) : (
    <Spinner />
  );
};

export default Banking;
