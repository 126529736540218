import 'bootstrap/dist/css/bootstrap.min.css';
import 'rc-menu/assets/index.css';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/custom.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from './hooks/useAuth';
import App from './App';

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById('root')
);
