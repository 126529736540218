import React, { useState, useEffect } from 'react';
import { Container, Breadcrumb, Button, Modal, Row, Col, Form, Nav, Table } from 'react-bootstrap';
import Select from 'react-select';
import useEndpoint from '../hooks/useEndpoint';
import useAsyncEndpoint from '../hooks/useAsyncEndpoint';
import Spinner from '../components/Spinner';

const Pins = () => {
  const [pinUsers, setPinUsers] = useState(null);
  const [res] = useEndpoint({
    method: 'GET',
    url: 'pinUsers'
  });
  const [newPinRes, addPinUser] = useAsyncEndpoint(data => ({
    method: 'POST',
    url: `pinUsers/create`,
    data
  }));
  const [updateRes, updatePinUser] = useAsyncEndpoint((pinUserId, data) => ({
    method: 'POST',
    url: `pinUsers/${pinUserId}/update`,
    data
  }));
  const [resetPinCodeRes, resetPinCode] = useAsyncEndpoint(pinUserId => ({
    method: 'POST',
    url: `pinUsers/${pinUserId}/resetPinCode`
  }));
  const [activateRes, activate] = useAsyncEndpoint(pinUserId => ({
    method: 'POST',
    url: `pinUsers/${pinUserId}/activate`
  }));
  const [deactivateRes, deactivate] = useAsyncEndpoint(pinUserId => ({
    method: 'POST',
    url: `pinUsers/${pinUserId}/deactivate`
  }));

  useEffect(() => {
    if (res.data) setPinUsers(res.data.sort((a, b) => (b.id < a.id ? 1 : -1)));
  }, [res.pending]);

  useEffect(() => {
    if (pinUsers && updateRes.data) {
      const newPinUsers = [...pinUsers.filter(u => u.id !== updateRes.data.id), updateRes.data];
      setPinUsers(newPinUsers.sort((a, b) => (b.id < a.id ? 1 : -1)));
    }
  }, [updateRes.pending]);
  useEffect(() => {
    if (pinUsers && newPinRes.data) {
      const newPinUsers = [...pinUsers, newPinRes.data];
      setPinUsers(newPinUsers.sort((a, b) => (b.id < a.id ? 1 : -1)));
    }
  }, [newPinRes.pending]);
  useEffect(() => {
    if (pinUsers && resetPinCodeRes.data) {
      const newPinUsers = [...pinUsers.filter(u => u.id !== resetPinCodeRes.data.id), resetPinCodeRes.data];
      setPinUsers(newPinUsers.sort((a, b) => (b.id < a.id ? 1 : -1)));
    }
  }, [resetPinCodeRes.pending]);
  useEffect(() => {
    if (pinUsers && activateRes.data) {
      const newPinUsers = [...pinUsers.filter(u => u.id !== activateRes.data.id), activateRes.data];
      setPinUsers(newPinUsers.sort((a, b) => (b.id < a.id ? 1 : -1)));
    }
  }, [activateRes.pending]);
  useEffect(() => {
    if (pinUsers && deactivateRes.data) {
      const newPinUsers = [...pinUsers.filter(u => u.id !== deactivateRes.data.id), deactivateRes.data];
      setPinUsers(newPinUsers.sort((a, b) => (b.id < a.id ? 1 : -1)));
    }
  }, [deactivateRes.pending]);

  const handleClickAdd = () => addPinUser({ name: 'Name', role: 'Staff' });
  const toggleActivation = pinUser => {
    if (pinUser.active) {
      deactivate(pinUser.id);
    } else {
      activate(pinUser.id);
    }
  };

  return pinUsers ? (
    <Container fluid style={{ paddingTop: '1rem' }}>
      <Breadcrumb>
        <Breadcrumb.Item>Configuration</Breadcrumb.Item>
        <Breadcrumb.Item active>Pins</Breadcrumb.Item>
      </Breadcrumb>
      <Table>
        <thead>
          <tr style={{ background: '#343a40', color: 'white', fontWeight: 'bold' }}>
            <td>Name</td>
            <td>Role</td>
            <td>Pin Code</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {pinUsers.map(pinUser => (
            <tr key={pinUser.id}>
              <td>
                <Form.Control
                  defaultValue={pinUser.name}
                  onBlur={e => updatePinUser(pinUser.id, { name: e.target.value })}
                />
              </td>
              <td>
                <Select
                  value={{ value: pinUser.role, label: pinUser.role }}
                  options={[{ value: 'Supervisor', label: 'Supervisor' }, { value: 'Staff', label: 'Staff' }]}
                  onChange={val => updatePinUser(pinUser.id, { role: val.value })}
                />
              </td>
              <td>{pinUser.pinCode}</td>
              <td>
                <Nav.Link
                  style={{ padding: `0 10px 0 ${pinUser.active ? '10' : '26'}px`, float: 'right', display: 'inline' }}
                  onClick={() => toggleActivation(pinUser)}
                >
                  {pinUser.active ? 'deactivate' : 'activate'}
                </Nav.Link>
                <Nav.Link
                  style={{ padding: '0 10px 0 0', float: 'right', display: 'inline' }}
                  onClick={() => resetPinCode(pinUser.id)}
                >
                  reset pin
                </Nav.Link>
              </td>
            </tr>
          ))}
          <tr>
            <td style={{ borderTop: 0 }}>
              <Nav.Link style={{ padding: 0 }} onClick={handleClickAdd}>
                add new pin
              </Nav.Link>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  ) : (
    <Spinner />
  );
};

export default Pins;
