import React, { useState, useEffect } from 'react';
import { Container, Breadcrumb, Button, Modal, Row, Col, Nav, Table, Form } from 'react-bootstrap';
import useEndpoint from '../../hooks/useEndpoint';
import useAsyncEndpoint from '../../hooks/useAsyncEndpoint';
import Spinner from '../../components/Spinner';
import useBranch, { BranchProvider } from './useBranch';
import Overview from './Overview';
import Schedule from './Schedule';
import Tills from './Tills';

const EditModal = ({ show, onClose }) => {
  const { loading, saving } = useBranch();
  const [savingText, setSavingText] = useState('');

  useEffect(() => {
    if (saving) {
      setSavingText('saving...');
    } else if (!loading) {
      const _t1 = setTimeout(() => setSavingText('saved'), 500);
      const _t2 = setTimeout(() => setSavingText(''), 2000);
      return () => {
        clearTimeout(_t1);
        clearTimeout(_t2);
      };
    }
  }, [saving]);

  return (
    <Modal show={show} size="xl" onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Branch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <Row>
              <Col>
                <Overview style={{ marginBottom: '1rem', minHeight: '34rem' }} />
              </Col>
              <Col>
                <Schedule style={{ marginBottom: '1rem', minHeight: '34rem' }} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Tills />
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <span style={{ marginRight: 'auto' }}>{savingText}</span>
        <Button variant="secondary" disabled={false} onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddModal = ({ show, onClose }) => {
  const [createBranchRes, createBranch] = useAsyncEndpoint(data => ({
    method: 'POST',
    url: 'branches/create',
    data
  }));

  useEffect(() => {
    if (createBranchRes.data) onClose();
  }, [createBranchRes.pending]);

  const handleSubmit = e => {
    e.preventDefault();
    createBranch({
      name: e.target.name.value,
      address: e.target.address.value,
      loginEmail: e.target.loginEmail.value,
      loginPassword: e.target.loginPassword.value,
      safeFloat: 50,
      schedule: { daysClosed: [], datesClosed: [] }
    });
  };

  return (
    <Modal show={show} size="xl" onHide={onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add Branch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Name
            </Form.Label>
            <Col sm="8">
              <Form.Control name="name" defaultValue="New branch name" required />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Address
            </Form.Label>
            <Col sm="8">
              <Form.Control name="address" defaultValue="Branch address" required />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Login Email
            </Form.Label>
            <Col sm="8">
              <Form.Control name="loginEmail" required />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Login Password
            </Form.Label>
            <Col sm="8">
              <Form.Control type="password" name="loginPassword" required />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const Branches = () => {
  const [branches, setBranches] = useState(null);
  const [res, refresh] = useEndpoint({
    method: 'GET',
    url: 'branches'
  });

  const [activateRes, activate] = useAsyncEndpoint(branchId => ({
    method: 'POST',
    url: `branches/${branchId}/activate`
  }));
  const [deactivateRes, deactivate] = useAsyncEndpoint(branchId => ({
    method: 'POST',
    url: `branches/${branchId}/deactivate`
  }));

  useEffect(() => {
    if (res.data) setBranches(res.data.sort((a, b) => (b.id < a.id ? 1 : -1)));
  }, [res.pending]);

  useEffect(() => {
    if (branches && activateRes.data) {
      const newBranches = [...branches.filter(u => u.id !== activateRes.data.id), activateRes.data];
      setBranches(newBranches.sort((a, b) => (b.id < a.id ? 1 : -1)));
    }
  }, [activateRes.pending]);

  useEffect(() => {
    if (branches && deactivateRes.data) {
      const newBranches = [...branches.filter(u => u.id !== deactivateRes.data.id), deactivateRes.data];
      setBranches(newBranches.sort((a, b) => (b.id < a.id ? 1 : -1)));
    }
  }, [deactivateRes.pending]);

  const [selected, setSelected] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const handleClickEdit = branchId => {
    setSelected(branchId);
    setEditModalOpen(true);
  };
  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setSelected(null);
    refresh();
  };
  const handleClickAdd = () => setAddModalOpen(true);
  const handleAddModalClose = () => {
    setAddModalOpen(false);
    refresh();
  };

  const toggleActivation = branch => {
    if (branch.active) {
      deactivate(branch.id);
    } else {
      activate(branch.id);
    }
  };

  return branches ? (
    <Container fluid style={{ paddingTop: '1rem' }}>
      <Breadcrumb>
        <Breadcrumb.Item>Configuration</Breadcrumb.Item>
        <Breadcrumb.Item active>Branches</Breadcrumb.Item>
      </Breadcrumb>
      <Table>
        <thead>
          <tr style={{ background: '#343a40', color: 'white', fontWeight: 'bold' }}>
            <td>Name</td>
            <td>Address</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {branches.map(branch => (
            <tr key={branch.id}>
              <td>{branch.name}</td>
              <td>{branch.address}</td>
              <td>
                <Nav.Link
                  style={{ padding: '0 10px 0 10px', float: 'right', display: 'inline' }}
                  onClick={() => toggleActivation(branch)}
                >
                  {branch.active ? 'deactivate' : 'activate'}
                </Nav.Link>
                <Nav.Link
                  style={{ padding: '0 10px 0 0', float: 'right', display: 'inline' }}
                  onClick={() => handleClickEdit(branch.id)}
                >
                  edit
                </Nav.Link>
              </td>
            </tr>
          ))}
          <tr>
            <td style={{ borderTop: 0 }}>
              <Nav.Link style={{ padding: 0 }} onClick={handleClickAdd}>
                add new branch
              </Nav.Link>
            </td>
          </tr>
        </tbody>
      </Table>

      {selected && (
        <BranchProvider branchId={selected}>
          <EditModal show={editModalOpen} onClose={handleEditModalClose} />
        </BranchProvider>
      )}
      <AddModal show={addModalOpen} onClose={handleAddModalClose} />
    </Container>
  ) : (
    <Spinner />
  );
};

export default Branches;
