import moment from 'moment-timezone';
import React from 'react';
import { Row, Col, Form, Card } from 'react-bootstrap';
import useBranch from './useBranch';
import DecimalInput from '../../components/DecimalInput';

const Overview = ({ style }) => {
  const { branch, user, updateBranch } = useBranch();

  return (
    <Card style={style}>
      <Card.Header>Overview</Card.Header>
      <Card.Body>
        <Form.Group as={Row}>
          <Form.Label column sm="4">
            Name
          </Form.Label>
          <Col sm="8">
            <Form.Control defaultValue={branch.name} onBlur={e => updateBranch({ name: e.target.value })} />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="4">
            Address
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as="textarea"
              rows="4"
              defaultValue={branch.address}
              onBlur={e => updateBranch({ address: e.target.value })}
              style={{ resize: 'none' }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="4">
            Safe Float
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as={DecimalInput}
              value={branch.safeFloat}
              onChange={val => updateBranch({ safeFloat: val })}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="4">
            Login Email
          </Form.Label>
          <Col sm="8">
            <Form.Control plaintext disabled value={user.email} />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="4">
            Setup Date
          </Form.Label>
          <Col sm="8">
            <Form.Control plaintext disabled value={moment(branch.setupDate).format('DD/MM/YYYY')} />
          </Col>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default Overview;
