import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Card, Table } from 'react-bootstrap';
import useEndpoint from '../hooks/useEndpoint';
import useAuth from '../hooks/useAuth';

const RecentBanking = ({ style }) => {
  const { session } = useAuth();
  const [res] = useEndpoint({
    method: 'GET',
    url: `withdrawals/retrieveRecentForBranch/${session.branchId}?limit=${5}`
  });

  const recents = res.complete && !res.error ? res.data : [];

  return (
    <Card style={style}>
      <Card.Header>Recent Banking</Card.Header>
      <Card.Body>
        <Table borderless>
          <thead>
            <tr style={{ background: '#343a40', color: 'white' }}>
              <td>Date</td>
              <td>Total Banked</td>
              <td>Approved By</td>
            </tr>
          </thead>
          <tbody>
            {recents.map(row => (
              <tr key={row.id}>
                <td>
                  {moment
                    .utc(row.date)
                    .tz('Europe/London')
                    .format('YYYY-MM-DD')}
                </td>
                <td>£{row.amount.toFixed(2)}</td>
                <td>{row.approvedByName}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default RecentBanking;
