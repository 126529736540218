import React, { useState } from 'react';
import { InputGroup, Button, FormControl, Modal, Row, Col, Form, Table, Nav } from 'react-bootstrap';
import DecimalInput from '../../../../components/DecimalInput';

const initialFormData = { accountNo: '', name: '' };

const ModalForm = ({ rows = [], onChange, show, onClose }) => {
  const [data, setData] = useState(initialFormData);

  const handleChange = (key, val) => setData({ ...data, [key]: val });
  const handleAdd = () => {
    onChange([...rows, { accountNo: data.accountNo, name: data.name, amount: data.amount }]);
    setData(initialFormData);
  };
  const handleRemove = index => onChange(rows.filter((_, i) => i !== index));

  const isAddDisabled = () => data.accountNo === '' || data.name === '' || data.amount === undefined;

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Cheques</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col style={{ flex: 2 }}>
            Account No
            <Form.Control
              type="text"
              value={data.accountNo}
              onChange={e => handleChange('accountNo', e.target.value)}
            />
          </Col>
          <Col style={{ flex: 2 }}>
            Name
            <Form.Control type="text" value={data.name} onChange={e => handleChange('name', e.target.value)} />
          </Col>
          <Col style={{ flex: 2 }}>
            Amount
            <Form.Control as={DecimalInput} value={data.amount} onChange={val => handleChange('amount', val)} />
          </Col>
          <Col style={{ marginTop: 'auto', flex: 1 }}>
            <Button onClick={handleAdd} style={isAddDisabled() ? { opacity: '0.6' } : {}}>
              Add
            </Button>
          </Col>
        </Row>
        {rows.length > 0 && <br />}
        {rows.length > 0 && (
          <Table>
            <thead>
              <tr style={{ background: '#343a40', color: 'white', fontWeight: 'bold' }}>
                <td>Account No</td>
                <td>Name</td>
                <td>Amount</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => (
                <tr key={i}>
                  <td>{row.accountNo}</td>
                  <td>{row.name}</td>
                  <td>{row.amount.toFixed(2)}</td>
                  <td>
                    <Nav.Link style={{ padding: '0 10px 0 0', textAlign: 'right' }} onClick={() => handleRemove(i)}>
                      delete
                    </Nav.Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Cheques = ({ value, onChange }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  const renderTotal = () => value.reduce((acc, row) => acc + row.amount, 0).toFixed(2);

  return (
    <InputGroup>
      <ModalForm show={modalOpen} rows={value} onChange={onChange} onClose={() => toggleModal(false)} />
      <InputGroup.Prepend>
        <Button onClick={() => toggleModal(true)} variant="secondary">
          Enter
        </Button>
      </InputGroup.Prepend>
      <FormControl type={'text'} value={renderTotal()} disabled={true} style={{ textAlign: 'right' }} />
    </InputGroup>
  );
};

export default Cheques;
