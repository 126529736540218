import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import useEndpoint from '../hooks/useEndpoint';
import useAuth from '../hooks/useAuth';
import useHistory from '../hooks/useHistory';
import Spinner from '../components/Spinner';
import Missed from './Missed';
import Session from './Session';

const NoOutstandingSubmissions = () => <div>No outstanding submissions</div>;

const Prescriptions = ({ location, match }) => {
  const [routing, setRouting] = useState(true);
  const history = useHistory();
  const { session } = useAuth();
  const [missedRes, refreshMissed] = useEndpoint({
    method: 'GET',
    url: `nhsSessions/missedSessions?branchId=${session.branchId}`
  });
  const [todayCompleteRes, refreshTodayComplete] = useEndpoint({
    method: 'GET',
    url: `nhsSessions/isCompleteForToday?branchId=${session.branchId}`
  });

  useEffect(() => {
    if (!routing && location.pathname === match.path && missedRes.complete && todayCompleteRes) {
      setRouting(true);
      refreshMissed();
      refreshTodayComplete();
      return;
    }
    if (!missedRes.complete || missedRes.error || !todayCompleteRes.complete || todayCompleteRes.error) return;

    if (location.pathname === `${match.path}/session`) {
    } else if (missedRes.data.length > 0 && location.pathname !== `${match.path}/missed`) {
      history.replace(`${match.path}/missed`);
    } else if (
      missedRes.data.length === 0 &&
      todayCompleteRes.data &&
      location.pathname !== `${match.path}/no-outstanding`
    ) {
      history.replace(`${match.path}/no-outstanding`);
    } else if (missedRes.data.length === 0 && !todayCompleteRes.data && location.pathname !== `${match.path}/session`) {
      const today = moment.tz('Europe/London').format('YYYY-MM-DD');
      history.replace(`${match.path}/session?branchId=${session.branchId}&date=${today}`);
    }

    setRouting(false);
  }, [missedRes, todayCompleteRes, location]);

  return (
    <Switch>
      <Route exact path={match.path} component={Spinner} />
      <Route path={`${match.path}/no-outstanding`} component={NoOutstandingSubmissions} />
      <Route path={`${match.path}/missed`} component={Missed} />
      <Route path={`${match.path}/session`} component={Session} />
    </Switch>
  );
};

export default Prescriptions;
