import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Breadcrumb, Nav, Table, Modal, Form, Button } from 'react-bootstrap';
import useEndpoint from '../hooks/useEndpoint';
import useAsyncEndpoint from '../hooks/useAsyncEndpoint';
import Spinner from '../components/Spinner';

const ChangePasswordModal = ({ show, onClose, email }) => {
  const [passwordRes, setPassword] = useAsyncEndpoint(password => ({
    method: 'POST',
    url: `users/resetPassword`,
    data: { email, password }
  }));

  const handleSubmit = e => {
    e.preventDefault();
    setPassword(e.target.field.value);
  };

  useEffect(() => {
    if (passwordRes.complete) onClose();
  }, [passwordRes.pending]);

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              New Password
            </Form.Label>
            <Col sm="8">
              <Form.Control autoFocus type="password" name="field" />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const Users = () => {
  const [users, setUsers] = useState(null);
  const [res] = useEndpoint({
    method: 'GET',
    url: 'users'
  });

  const [activateRes, _activate] = useAsyncEndpoint(userId => ({
    method: 'POST',
    url: `users/${userId}/activate`
  }));
  const [deactivateRes, _deactivate] = useAsyncEndpoint(userId => ({
    method: 'POST',
    url: `users/${userId}/deactivate`
  }));

  useEffect(() => {
    if (res.data) setUsers(res.data.sort((a, b) => (b.id < a.id ? 1 : -1)));
  }, [res.pending]);

  useEffect(() => {
    if (users && activateRes.data) {
      const newUsers = [...users.filter(u => u.id !== activateRes.data.id), activateRes.data];
      setUsers(newUsers.sort((a, b) => (b.id < a.id ? 1 : -1)));
    }
  }, [activateRes.pending]);

  useEffect(() => {
    if (users && deactivateRes.data) {
      const newUsers = [...users.filter(u => u.id !== deactivateRes.data.id), deactivateRes.data];
      setUsers(newUsers.sort((a, b) => (b.id < a.id ? 1 : -1)));
    }
  }, [deactivateRes.pending]);

  const toggleActivation = user => {
    if (user.active) {
      _deactivate(user.id);
    } else {
      _activate(user.id);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const handleClose = () => {
    setModalOpen(false);
    setSelectedEmail(null);
  };

  return users ? (
    <Container fluid style={{ paddingTop: '1rem' }}>
      <Breadcrumb>
        <Breadcrumb.Item>Configuration</Breadcrumb.Item>
        <Breadcrumb.Item active>Users</Breadcrumb.Item>
      </Breadcrumb>
      <Table>
        <thead>
          <tr style={{ background: '#343a40', color: 'white', fontWeight: 'bold' }}>
            <td>Email</td>
            <td>Role</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <Nav.Link
                  style={{ padding: `0 10px 0 ${user.active ? '10' : '26'}px`, float: 'right', display: 'inline' }}
                  onClick={() => toggleActivation(user)}
                >
                  {user.active ? 'deactivate' : 'activate'}
                </Nav.Link>
                <Nav.Link
                  style={{ padding: `0 10px 0 0`, float: 'right', display: 'inline' }}
                  onClick={() => {
                    setSelectedEmail(user.email);
                    setModalOpen(true);
                  }}
                >
                  set password
                </Nav.Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {selectedEmail && <ChangePasswordModal show={modalOpen} onClose={handleClose} email={selectedEmail} />}
    </Container>
  ) : (
    <Spinner />
  );
};

export default Users;
