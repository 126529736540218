import React, { useState, useEffect } from 'react';
import { Modal, Card, Button, Form } from 'react-bootstrap';
import aggregateCash from './aggregateCash';
import useAsyncEndpoint from '../hooks/useAsyncEndpoint';
import useForm from '../hooks/useForm';
import useAuth from '../hooks/useAuth';

const PinModal = ({ show, onClose, onSubmitted }) => {
  const [pinCode, setPinCode] = useState('');
  const [running, setRunning] = useState(false);

  const { data: formData, reset: resetForm } = useForm();
  const { session } = useAuth();
  const [res, submit] = useAsyncEndpoint(data => ({ method: 'POST', url: 'withdrawals/withdraw', data }));

  useEffect(() => {
    if (res.pending) {
      setRunning(true);
    } else if (res.complete && !res.error) {
      setRunning(false);
      resetForm();
      setPinCode('');
      onSubmitted();
    } else if (res.error) {
      setRunning(false);
      // set error message
    }
  }, [res]);

  const handleChange = e => setPinCode(e.target.value);
  const handleSubmit = e => {
    e.preventDefault();
    const amount = aggregateCash(formData);
    const data = { pinCode, branchId: session.branchId, amount };
    submit(data);
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton={!running}>
          <Modal.Title>Submit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {running ? (
            <p>Loading...</p>
          ) : (
            <>
              <p>Pin</p>
              <p>Please enter your PIN number to submit.</p>
              <Form.Group>
                <Form.Control type="password" value={pinCode} onChange={handleChange} />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={running} onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={running}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const Submit = ({ disabled, onSubmitted }) => {
  const [show, setShow] = useState(false);

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleSubmitted = () => {
    setShow(false);
    onSubmitted();
  };

  return (
    <Card>
      <Card.Body style={{ textAlign: 'right' }}>
        <Button disabled={disabled} onClick={handleOpen}>
          Submit Data
        </Button>
      </Card.Body>
      <PinModal show={show} onClose={handleClose} onSubmitted={handleSubmitted} />
    </Card>
  );
};

export default Submit;
