import React, { useState, useEffect } from 'react';
import { Modal, Card, Button, Form } from 'react-bootstrap';
import useAsyncEndpoint from '../../hooks/useAsyncEndpoint';
import useForm from '../../hooks/useForm';
import useAuth from '../../hooks/useAuth';
import useHistory from '../../hooks/useHistory';

const PinModal = ({ show, onClose, date }) => {
  const [pinCode, setPinCode] = useState('');
  const [running, setRunning] = useState(false);

  const { data: formData, reset: resetForm } = useForm();
  const { session } = useAuth();
  const [res, submit] = useAsyncEndpoint(data => ({ method: 'POST', url: 'nhsSessions/submit', data }));
  const history = useHistory();

  useEffect(() => {
    if (res.pending) {
      setRunning(true);
    } else if (res.complete && !res.error) {
      setRunning(false);
      resetForm();
      history.push('/prescriptions');
    } else if (res.error) {
      setRunning(false);
      // set error message
    }
  }, [res]);

  const handleChange = e => setPinCode(e.target.value);
  const handleSubmit = e => {
    e.preventDefault();
    const data = Object.assign(formData, { pinCode, date, branchId: session.branchId });
    submit(data);
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton={!running}>
          <Modal.Title>Submit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {running ? (
            <p>Loading...</p>
          ) : (
            <>
              <p>Pin</p>
              <p>Please enter your PIN number to submit.</p>
              <Form.Group>
                <Form.Control type="password" value={pinCode} onChange={handleChange} />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={running} onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={running}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const Submit = ({ date }) => {
  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <Card>
      <Card.Body style={{ textAlign: 'right' }}>
        <Button onClick={handleOpen}>Submit Data</Button>
      </Card.Body>
      <PinModal show={show} onClose={handleClose} date={date} />
    </Card>
  );
};

export default Submit;
