import React from 'react';
import TopNav from './TopNav';
import SideNav from './SideNav';

const Chrome = ({ navConfig, children }) => {
  return (
    <div>
      <TopNav />
      <div
        style={{
          minHeight: 'calc(100vh - 60px)',
          position: 'relative'
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 60,
            left: 0,
            width: 186,
            height: 'calc(100% - 60px)',
            position: 'fixed'
          }}
        >
          <SideNav navConfig={navConfig} />
        </div>

        <div
          style={{
            marginLeft: 186
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Chrome;
