import React, { createContext, useContext, useState, useEffect } from 'react';
import useEndpoint from '../../hooks/useEndpoint';
import useAsyncEndpoint from '../../hooks/useAsyncEndpoint';

const BranchContext = createContext();

export const BranchProvider = ({ branchId, children }) => {
  const [res] = useEndpoint({ method: 'GET', url: `branches/${branchId}` });
  const [userRes] = useEndpoint({ method: 'GET', url: `branches/${branchId}/user` });
  const [tillRes] = useEndpoint({ method: 'GET', url: `branches/${branchId}/tills` });
  const [updateRes, _updateBranch] = useAsyncEndpoint(data => ({
    method: 'POST',
    url: `branches/${branchId}/update`,
    data
  }));
  const [newTillRes, _addNewTill] = useAsyncEndpoint(data => ({
    method: 'POST',
    url: `tills/create`,
    data: Object.assign({}, data, { branchId })
  }));
  const [updateTillRes, _updateTill] = useAsyncEndpoint((tillId, data) => ({
    method: 'POST',
    url: `tills/${tillId}/update`,
    data: Object.assign({}, data, { branchId })
  }));

  const [branch, setBranch] = useState(null);
  const [user, setUser] = useState(null);
  const [tills, setTills] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const updateBranch = data => {
    if (saving) return;
    setSaving(true);
    _updateBranch(data);
  };

  const addTill = data => {
    if (saving) return;
    setSaving(true);
    _addNewTill(data);
  };

  const updateTill = (tillId, data) => {
    if (saving) return;
    setSaving(true);
    _updateTill(tillId, data);
  };

  useEffect(() => {
    if (res.complete && !res.error && userRes.complete && !userRes.error && tillRes.complete && !tillRes.error) {
      setBranch(res.data);
      setUser(userRes.data);
      setTills(tillRes.data.sort((t1, t2) => t1.tillNumber - t2.tillNumber));
      setLoading(false);
    }
  }, [res, userRes, tillRes]);

  useEffect(() => {
    if (updateRes.pending || newTillRes.pending || updateTillRes.pending) {
      setSaving(true);
    } else if (
      !updateRes.pending &&
      !updateRes.error &&
      !newTillRes.pending &&
      !newTillRes.error &&
      !updateTillRes.pending &&
      !updateTillRes.error
    ) {
      if (updateRes.data) {
        setBranch(updateRes.data);
      }
      if (newTillRes.data) {
        const nTills = [...tills, newTillRes.data].sort((t1, t2) => t1.tillNumber - t2.tillNumber);
        setTills(nTills);
      }
      if (updateTillRes.data) {
        const uTill = Object.assign({}, tills.filter(t => t.id === updateTillRes.data.id)[0], updateTillRes.data);
        const nTills = [...tills.filter(t => t.id !== updateTillRes.data.id), uTill].sort(
          (t1, t2) => t1.tillNumber - t2.tillNumber
        );
        setTills(nTills);
      }

      setSaving(false);
    }
  }, [updateRes.pending, newTillRes.pending, updateTillRes.pending]);

  return (
    <BranchContext.Provider value={{ loading, saving, branch, user, tills, updateBranch, addTill, updateTill }}>
      {children}
    </BranchContext.Provider>
  );
};

export default () => useContext(BranchContext);
