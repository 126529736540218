import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { HistoryProvider } from './hooks/useHistory';
import Chrome from './components/Chrome';
import Branches from './Configuration/Branches';
import Users from './Configuration/Users';
import Pins from './Configuration/Pins';
import General from './Configuration/General';

const Dashboard = () => <div>Dashboard</div>;

const App = () => {
  const navConfig = [
    {
      label: 'Dashboard',
      eventKey: '/',
      icon: 'fa-columns'
    },
    {
      label: 'Configuration',
      children: [
        {
          label: 'Branches',
          eventKey: '/configuration/branches'
        },
        {
          label: 'Users',
          eventKey: '/configuration/users'
        },
        {
          label: 'Pins',
          eventKey: '/configuration/pins'
        },
        {
          label: 'General',
          eventKey: '/configuration/general'
        }
      ]
    },
    {
      label: 'Reports',
      children: [
        {
          label: 'Report 1',
          eventKey: '/reports/report1'
        }
      ]
    }
  ];

  return (
    <BrowserRouter>
      <HistoryProvider>
        <Chrome navConfig={navConfig}>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/configuration/branches" component={Branches} />
            <Route path="/configuration/users" component={Users} />
            <Route path="/configuration/pins" component={Pins} />
            <Route path="/configuration/general" component={General} />
            <Redirect to="/" />
          </Switch>
        </Chrome>
      </HistoryProvider>
    </BrowserRouter>
  );
};

export default App;
