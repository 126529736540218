import React, { useState, useEffect } from 'react';
import { Container, Breadcrumb, Button, Modal, Row, Col, Form, Nav, Table, Card } from 'react-bootstrap';
import useEndpoint from '../hooks/useEndpoint';
import useAsyncEndpoint from '../hooks/useAsyncEndpoint';
import Spinner from '../components/Spinner';
import { cpus } from 'os';

const General = () => {
  const [cardTypes, setCardTypes] = useState(null);
  const [couponTypes, setCouponTypes] = useState(null);
  const [payoutCategories, setPayoutCategories] = useState(null);
  const [careHomes, setCareHomes] = useState(null);
  const [publicHolidays, setPublicHolidays] = useState(null);

  const [cardTypesRes] = useEndpoint({
    method: 'GET',
    url: 'cardTypes'
  });
  const [couponTypesRes] = useEndpoint({
    method: 'GET',
    url: 'couponTypes'
  });
  const [payoutCategoriesRes] = useEndpoint({
    method: 'GET',
    url: 'payoutCategories'
  });
  const [careHomesRes] = useEndpoint({
    method: 'GET',
    url: 'careHomes'
  });
  const [publicHolidaysRes] = useEndpoint({
    method: 'GET',
    url: 'publicHolidays'
  });

  useEffect(() => {
    if (cardTypesRes.data) setCardTypes(cardTypesRes.data.sort((a, b) => (b < a ? 1 : -1)));
    if (couponTypesRes.data) setCouponTypes(couponTypesRes.data.sort((a, b) => (b < a ? 1 : -1)));
    if (payoutCategoriesRes.data) setPayoutCategories(payoutCategoriesRes.data.sort((a, b) => (b < a ? 1 : -1)));
    if (careHomesRes.data) setCareHomes(careHomesRes.data.sort((a, b) => (b.id < a.id ? 1 : -1)));
    if (publicHolidaysRes.data) setPublicHolidays(publicHolidaysRes.data.sort((a, b) => (b < a ? 1 : -1)));
  }, [
    cardTypesRes.pending,
    couponTypesRes.pending,
    payoutCategoriesRes.pending,
    careHomesRes.pending,
    publicHolidaysRes.pending
  ]);

  const [addCardTypeRes, addCardType] = useAsyncEndpoint(type => ({
    method: 'POST',
    url: `cardTypes/createMany`,
    data: { types: [type] }
  }));
  const [addCouponTypeRes, addCouponType] = useAsyncEndpoint(type => ({
    method: 'POST',
    url: `couponTypes/createMany`,
    data: { types: [type] }
  }));
  const [addPayoutCategoryRes, addPayoutCategory] = useAsyncEndpoint(category => ({
    method: 'POST',
    url: `payoutCategories/createMany`,
    data: { categories: [category] }
  }));
  const [addCareHomeRes, addCareHome] = useAsyncEndpoint(name => ({
    method: 'POST',
    url: `careHomes/create`,
    data: { name }
  }));
  const [addPublicHolidayRes, addPublicHoliday] = useAsyncEndpoint(date => ({
    method: 'POST',
    url: `publicHolidays/createMany`,
    data: { dates: [date] }
  }));

  const [updateCareHomeRes, updateCareHome] = useAsyncEndpoint((id, name) => ({
    method: 'POST',
    url: `careHomes/${id}/update`,
    data: { name }
  }));

  const [deleteCardTypeRes, deleteCardType] = useAsyncEndpoint(type => ({
    method: 'POST',
    url: 'cardTypes/deleteMany',
    data: { types: [type] }
  }));
  const [deleteCouponTypeRes, deleteCouponType] = useAsyncEndpoint(type => ({
    method: 'POST',
    url: 'couponTypes/deleteMany',
    data: { types: [type] }
  }));
  const [deletePayoutCategoryRes, deletePayoutCategory] = useAsyncEndpoint(category => ({
    method: 'POST',
    url: 'payoutCategories/deleteMany',
    data: { categories: [category] }
  }));
  const [deleteCareHomeRes, deleteCareHome] = useAsyncEndpoint(id => ({
    method: 'DELETE',
    url: `careHomes/${id}`
  }));
  const [deletePublicHolidayRes, deletePublicHoliday] = useAsyncEndpoint(date => ({
    method: 'POST',
    url: 'publicHolidays/deleteMany',
    data: { dates: [date] }
  }));

  useEffect(() => {
    if (cardTypes && addCardTypeRes.data) {
      const newCardTypes = [...cardTypes, ...addCardTypeRes.data];
      setCardTypes(newCardTypes);
    }
  }, [addCardTypeRes.pending]);

  useEffect(() => {
    if (couponTypes && addCouponTypeRes.data) {
      const newCouponTypes = [...couponTypes, ...addCouponTypeRes.data];
      setCouponTypes(newCouponTypes);
    }
  }, [addCouponTypeRes.pending]);

  useEffect(() => {
    if (payoutCategories && addPayoutCategoryRes.data) {
      const newPayoutCategories = [...payoutCategories, ...addPayoutCategoryRes.data];
      setPayoutCategories(newPayoutCategories);
    }
  }, [addPayoutCategoryRes.pending]);

  useEffect(() => {
    if (careHomes && addCareHomeRes.data) {
      const newCareHomes = [...careHomes, addCareHomeRes.data];
      setCareHomes(newCareHomes);
    }
  }, [addCareHomeRes.pending]);

  useEffect(() => {
    if (publicHolidays && addPublicHolidayRes.data) {
      const newPublicHolidays = [...publicHolidays, ...addPublicHolidayRes.data];
      setPublicHolidays(newPublicHolidays);
    }
  }, [addPublicHolidayRes.pending]);

  useEffect(() => {
    if (careHomes && updateCareHomeRes.data) {
      const newCareHomes = [...careHomes.filter(c => c.id !== updateCareHomeRes.data.id), updateCareHomeRes.data];
      setCareHomes(newCareHomes.sort((a, b) => (b.id < a.id ? 1 : -1)));
    }
  }, [updateCareHomeRes.pending]);

  useEffect(() => {
    if (cardTypes && deleteCardTypeRes.data) {
      const newCardTypes = cardTypes.filter(c => !deleteCardTypeRes.data.includes(c));
      setCardTypes(newCardTypes);
    }
  }, [deleteCardTypeRes.pending]);

  useEffect(() => {
    if (couponTypes && deleteCouponTypeRes.data) {
      const newCouponTypes = couponTypes.filter(c => !deleteCouponTypeRes.data.includes(c));
      setCouponTypes(newCouponTypes);
    }
  }, [deleteCouponTypeRes.pending]);

  useEffect(() => {
    if (payoutCategories && deletePayoutCategoryRes.data) {
      const newPayoutCategories = payoutCategories.filter(c => !deletePayoutCategoryRes.data.includes(c));
      setPayoutCategories(newPayoutCategories);
    }
  }, [deletePayoutCategoryRes.pending]);

  useEffect(() => {
    if (careHomes && deleteCareHomeRes.data) {
      const newCareHomes = careHomes.filter(c => c.id !== deleteCareHomeRes.data.id);
      setCareHomes(newCareHomes);
    }
  }, [deleteCareHomeRes.pending]);

  useEffect(() => {
    if (publicHolidays && deletePublicHolidayRes.data) {
      const newPublicHolidays = publicHolidays.filter(c => !deletePublicHolidayRes.data.includes(c));
      setPublicHolidays(newPublicHolidays);
    }
  }, [deletePublicHolidayRes.pending]);

  const [insertMode, setInsertMode] = useState(null);

  const ready = cardTypes && couponTypes && payoutCategories && careHomes && publicHolidays;

  return ready ? (
    <Container fluid style={{ paddingTop: '1rem' }}>
      <Breadcrumb>
        <Breadcrumb.Item>Configuration</Breadcrumb.Item>
        <Breadcrumb.Item active>General</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col>
          <Card style={{ marginBottom: '1rem' }}>
            <Card.Header>Card Types</Card.Header>
            <Card.Body>
              <Table bordered>
                <thead>
                  <tr>
                    <td>
                      <strong>Type</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {cardTypes.map(cardType => (
                    <tr key={cardType}>
                      <td>
                        {cardType}
                        <Nav.Link
                          style={{ padding: '0 10px 0 0px', float: 'right' }}
                          onClick={() => deleteCardType(cardType)}
                        >
                          delete
                        </Nav.Link>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      {insertMode === 'CardType' ? (
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            addCardType(e.target.field.value);
                            setInsertMode(null);
                          }}
                        >
                          <Form.Control
                            style={{ maxWidth: '12rem', display: 'inline' }}
                            name="field"
                            autoFocus
                            onBlur={() => setInsertMode(null)}
                          />
                        </Form>
                      ) : (
                        <Nav.Link style={{ padding: '0 10px 0 0px' }} onClick={() => setInsertMode('CardType')}>
                          add new
                        </Nav.Link>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ marginBottom: '1rem' }}>
            <Card.Header>Coupon Types</Card.Header>
            <Card.Body>
              <Table bordered>
                <thead>
                  <tr>
                    <td>
                      <strong>Type</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {couponTypes.map(couponType => (
                    <tr key={couponType}>
                      <td>
                        {couponType}
                        <Nav.Link
                          style={{ padding: '0 10px 0 0px', float: 'right' }}
                          onClick={() => deleteCouponType(couponType)}
                        >
                          delete
                        </Nav.Link>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      {insertMode === 'CouponType' ? (
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            addCouponType(e.target.field.value);
                            setInsertMode(null);
                          }}
                        >
                          <Form.Control
                            style={{ maxWidth: '12rem', display: 'inline' }}
                            name="field"
                            autoFocus
                            onBlur={() => setInsertMode(null)}
                          />
                        </Form>
                      ) : (
                        <Nav.Link style={{ padding: '0 10px 0 0px' }} onClick={() => setInsertMode('CouponType')}>
                          add new
                        </Nav.Link>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ marginBottom: '1rem' }}>
            <Card.Header>Payout Categories</Card.Header>
            <Card.Body>
              <Table bordered>
                <thead>
                  <tr>
                    <td>
                      <strong>Category</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {payoutCategories.map(payoutCategory => (
                    <tr key={payoutCategory}>
                      <td>
                        {payoutCategory}
                        <Nav.Link
                          style={{ padding: '0 10px 0 0px', float: 'right' }}
                          onClick={() => deletePayoutCategory(payoutCategory)}
                        >
                          delete
                        </Nav.Link>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      {insertMode === 'PayoutCategory' ? (
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            addPayoutCategory(e.target.field.value);
                            setInsertMode(null);
                          }}
                        >
                          <Form.Control
                            style={{ maxWidth: '12rem', display: 'inline' }}
                            name="field"
                            autoFocus
                            onBlur={() => setInsertMode(null)}
                          />
                        </Form>
                      ) : (
                        <Nav.Link style={{ padding: '0 10px 0 0px' }} onClick={() => setInsertMode('PayoutCategory')}>
                          add new
                        </Nav.Link>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card style={{ marginBottom: '1rem' }}>
            <Card.Header>Care Homes</Card.Header>
            <Card.Body>
              <Table bordered>
                <thead>
                  <tr>
                    <td>
                      <strong>Name</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {careHomes.map(careHome => (
                    <tr key={careHome.id}>
                      <td>
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            updateCareHome(careHome.id, e.target.field.value);
                            e.target.field.blur();
                          }}
                        >
                          <Form.Control
                            style={{ maxWidth: '12rem', display: 'inline' }}
                            defaultValue={careHome.name}
                            name="field"
                          />
                          <Nav.Link
                            style={{ padding: '6px 10px 0 0', float: 'right' }}
                            onClick={() => deleteCareHome(careHome.id)}
                          >
                            delete
                          </Nav.Link>
                        </Form>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      {insertMode === 'CareHome' ? (
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            addCareHome(e.target.field.value);
                            setInsertMode(null);
                          }}
                        >
                          <Form.Control
                            style={{ maxWidth: '12rem', display: 'inline' }}
                            name="field"
                            autoFocus
                            onBlur={() => setInsertMode(null)}
                          />
                        </Form>
                      ) : (
                        <Nav.Link style={{ padding: '0 10px 0 0px' }} onClick={() => setInsertMode('CareHome')}>
                          add new
                        </Nav.Link>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ marginBottom: '1rem' }}>
            <Card.Header>Public Holidays</Card.Header>
            <Card.Body>
              <Table bordered>
                <thead>
                  <tr>
                    <td>
                      <strong>Date</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {publicHolidays.map(date => (
                    <tr key={date}>
                      <td>
                        {date}
                        <Nav.Link
                          style={{ padding: '0 10px 0 0px', float: 'right' }}
                          onClick={() => deletePublicHoliday(date)}
                        >
                          delete
                        </Nav.Link>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      {insertMode === 'PublicHoliday' ? (
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            addPublicHoliday(e.target.field.value);
                            setInsertMode(null);
                          }}
                        >
                          <Form.Control
                            style={{ maxWidth: '12rem', display: 'inline' }}
                            name="field"
                            autoFocus
                            onBlur={() => setInsertMode(null)}
                          />
                        </Form>
                      ) : (
                        <Nav.Link style={{ padding: '0 10px 0 0px' }} onClick={() => setInsertMode('PublicHoliday')}>
                          add new
                        </Nav.Link>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : (
    <Spinner />
  );
};

export default General;
