import _ from 'lodash';
import React from 'react';
import { Card, Form, Col, Row } from 'react-bootstrap';
import useForm from '../../hooks/useForm';
import DecimalInput from '../../components/DecimalInput';

const schema = {
  zero: { label: 'Zero (0%)', vatRate: 0 },
  standard: { label: 'Standard (20%)', vatRate: 0.2 },
  low: { label: 'Low (5%)', vatRate: 0.05 },
  nhsRX: { label: 'NHS Rx', vatRate: 0 },
  privateRX: { label: 'Private Rx', vatRate: 0 },
  travelClinic: { label: 'Travel Clinic', vatRate: 0 },
  privateServices: { label: 'Private Services', vatRate: 0 }
};

const TillReading = () => {
  const {
    data: { vatAnalysis },
    update
  } = useForm();

  const handleChange = (key, value) => update('vatAnalysis', { ...vatAnalysis, [key]: value });

  const renderVat = key => {
    if (_.isNil(vatAnalysis[key])) return '0.00';
    return (vatAnalysis[key] * schema[key].vatRate).toFixed(2);
  };
  const renderTotal = key => {
    if (_.isNil(vatAnalysis[key])) return '0.00';
    return (vatAnalysis[key] + vatAnalysis[key] * schema[key].vatRate).toFixed(2);
  };

  return (
    <Card>
      <Card.Header>VAT Analysis</Card.Header>
      <Card.Body>
        <Row>
          <Col></Col>
          <Col>Net Sales</Col>
          <Col>VAT</Col>
          <Col>Total</Col>
        </Row>
        <br />
        {Object.keys(schema).map((key, i) => (
          <span key={key}>
            {i > 0 && <br />}
            <Row>
              <Col>
                <Form.Label>{schema[key].label}</Form.Label>
              </Col>
              <Col>
                <Form.Control as={DecimalInput} value={vatAnalysis[key]} onChange={val => handleChange(key, val)} />
              </Col>
              <Col>
                <Form.Control disabled={true} type={'text'} value={renderVat(key)} style={{ textAlign: 'right' }} />
              </Col>
              <Col>
                <Form.Control disabled={true} type={'text'} value={renderTotal(key)} style={{ textAlign: 'right' }} />
              </Col>
            </Row>
          </span>
        ))}
      </Card.Body>
    </Card>
  );
};

export default TillReading;
