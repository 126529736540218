import _ from 'lodash';
import React, { useState } from 'react';
import { InputGroup, Button, FormControl, Modal, Row, Col, Form } from 'react-bootstrap';
import denominations from '../denominations';
import IntegerInput from '../../../../components/IntegerInput';

const ModalForm = ({ values, onChange, show, onClose }) => {
  const handleChange = (key, value) => onChange({ ...values, [key]: value });

  const getName = key => (key.includes('pound') ? `£${key.split('_')[1]}` : `${key.split('_')[1]}p`);
  const renderTotalForDenom = (key, value) => {
    if (_.isNil(value)) return '0.00';
    else return (denominations[key] * value).toFixed(2);
  };

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Cash Takings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>Denomination</Col>
          <Col>Qty</Col>
          <Col>Total</Col>
        </Row>
        {Object.keys(denominations).map(key => (
          <div key={key}>
            <br />
            <Row>
              <Col>
                <span style={{ paddingLeft: 6 }}>{getName(key)}</span>
              </Col>
              <Col>
                <Form.Control as={IntegerInput} value={values[key]} onChange={val => handleChange(key, val)} />
              </Col>
              <Col>
                <Form.Control
                  disabled={true}
                  style={{ textAlign: 'right' }}
                  type="text"
                  value={renderTotalForDenom(key, values[key])}
                />
              </Col>
            </Row>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Cash = ({ value, onChange }) => {
  const [modalOpen, toggleModal] = useState(false);

  const renderAggregate = values => {
    const total = Object.keys(values).reduce((acc, k) => {
      const val = _.isNil(values[k]) ? 0 : values[k];
      return acc + val * denominations[k];
    }, 0);
    return total.toFixed(2);
  };

  return (
    <InputGroup>
      <ModalForm show={modalOpen} values={value} onChange={onChange} onClose={() => toggleModal(false)} />
      <InputGroup.Prepend>
        <Button onClick={() => toggleModal(true)} variant="secondary">
          Enter
        </Button>
      </InputGroup.Prepend>
      <FormControl type={'text'} value={renderAggregate(value)} disabled={true} style={{ textAlign: 'right' }} />
    </InputGroup>
  );
};

export default Cash;
