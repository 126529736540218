import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Card, Form, Col, Row } from 'react-bootstrap';
import Cash from './inputs/Cash';
import Cheques from './inputs/Cheques';
import Cards from './inputs/Cards';
import Coupons from './inputs/Coupons';
import Payouts from './inputs/Payouts';
import useForm from '../../../hooks/useForm';
import aggregateCash from './aggregateCash';

const DISCREPANCY_THRESHOLD = 5.0;

const ActualTakings = () => {
  const {
    data: { actualTakings, tillReading },
    update
  } = useForm();

  const [totalTakings, setTotalTakings] = useState('0.00');
  const [discrepancy, setDiscrepancy] = useState('0.00');
  const [showNotes, setShowNotes] = useState(false);

  const handle = (key, value) => {
    update('actualTakings', { ...actualTakings, [key]: value });
  };

  useEffect(() => {
    const totalCash = aggregateCash(actualTakings.cash);
    const totalCards = actualTakings.cards.reduce((acc, c) => acc + c.amount, 0);
    const totalPayouts = actualTakings.payouts.reduce((acc, p) => acc + p.amount, 0);
    const zReading = tillReading.zReading || 0;

    const _discrepancy = totalCash + totalCards + totalPayouts - zReading;

    setTotalTakings((totalCash + totalCards).toFixed(2));
    setDiscrepancy(_discrepancy.toFixed(2));
    setShowNotes(Math.abs(_discrepancy) > DISCREPANCY_THRESHOLD);
  }, [actualTakings, tillReading]);

  const renderDiscrepancy = () => (discrepancy >= 0 ? `+${discrepancy}` : `${discrepancy}`);

  return (
    <Card>
      <Card.Header>Actual Takings</Card.Header>
      <Card.Body>
        <Row style={{ marginBottom: '1rem' }}>
          <Col>
            <Form.Label>Cash</Form.Label>
          </Col>
          <Col>
            <Cash value={actualTakings.cash} onChange={value => handle('cash', value)} />
          </Col>
        </Row>

        <Row style={{ marginBottom: '1rem' }}>
          <Col>
            <Form.Label>Cheques</Form.Label>
          </Col>
          <Col>
            <Cheques value={actualTakings.cheques} onChange={value => handle('cheques', value)} />
          </Col>
        </Row>

        <Row style={{ marginBottom: '1rem' }}>
          <Col>
            <Form.Label>Cards</Form.Label>
          </Col>
          <Col>
            <Cards value={actualTakings.cards} onChange={value => handle('cards', value)} />
          </Col>
        </Row>

        <Row style={{ marginBottom: '1rem' }}>
          <Col>
            <Form.Label>Coupons/Tokens/Vouchers</Form.Label>
          </Col>
          <Col>
            <Coupons value={actualTakings.coupons} onChange={value => handle('coupons', value)} />
          </Col>
        </Row>

        <Row style={{ marginBottom: '1rem' }}>
          <Col>
            <Form.Label>Payouts</Form.Label>
          </Col>
          <Col>
            <Payouts value={actualTakings.payouts} onChange={value => handle('payouts', value)} />
          </Col>
        </Row>

        <Row style={{ marginBottom: '1rem' }}>
          <Col>
            <Form.Label>Total Takings</Form.Label>
          </Col>
          <Col>
            <Form.Control
              disabled={true}
              style={{ textAlign: 'right', paddingRight: 0 }}
              type="number"
              value={totalTakings}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: '1rem' }}>
          <Col>
            <Form.Label>Discrepancy</Form.Label>
          </Col>
          <Col>
            <Form.Control disabled={true} style={{ textAlign: 'right' }} type="text" value={renderDiscrepancy()} />
          </Col>
        </Row>

        {showNotes && (
          <Row style={{ marginBottom: '1rem' }}>
            <Col>
              <Form.Label>Notes</Form.Label>
            </Col>
            <Col>
              <Form.Control type="text" value={actualTakings.notes} onChange={e => handle('notes', e.target.value)} />
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default ActualTakings;
