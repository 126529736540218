import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Container, Breadcrumb, Row, Col, Card } from 'react-bootstrap';
import Spinner from '../../components/Spinner';
import useEndpoint from '../../hooks/useEndpoint';
import useAuth from '../../hooks/useAuth';
import useHistory from '../../hooks/useHistory';

const getDate = () => moment.tz('Europe/London').format('YYYY-MM-DD');

const Till = ({ till }) => {
  const [hover, setHover] = useState(false);
  const history = useHistory();

  const handleEnter = () => setHover(true);
  const handleLeave = () => setHover(false);
  const handleClick = () => history.push(`session?tillId=${till.id}&date=${getDate()}`);

  return (
    <Col md={3} style={{ marginBottom: '1rem' }}>
      <Card
        bg="info"
        text="white"
        style={{
          overflow: 'hidden',
          height: '100%',
          textAlign: 'center',
          padding: '14px 0',
          borderWidth: 0,
          fontWeight: hover ? 500 : 300,
          transition: 'all 0.25s'
        }}
        className={hover ? 'shadow-lg' : ''}
        onClick={handleClick}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
      >
        <Card.Body>Till: {till.tillNumber}</Card.Body>
      </Card>
    </Col>
  );
};

const TillSelect = () => {
  const history = useHistory();
  const { session } = useAuth();
  const [res] = useEndpoint({
    method: 'GET',
    url: `cashUpSessions/missingForToday?branchId=${session.branchId}`
  });

  const handleBreadcrumbClick = () => history.push('/cashing-up');

  return res.complete && !res.error ? (
    <Container fluid style={{ paddingTop: '1rem' }}>
      <Breadcrumb>
        <Breadcrumb.Item onClick={handleBreadcrumbClick}>Cashing Up</Breadcrumb.Item>
        <Breadcrumb.Item active>Till Select</Breadcrumb.Item>
      </Breadcrumb>
      {res.data.length === 0 ? (
        <p>There are no outstanding tills to cash up.</p>
      ) : (
        <>
          <p>Please select a till to cash up.</p>
          <Row>
            {res.data.map(till => (
              <Till key={till.id} till={till} />
            ))}
          </Row>
        </>
      )}
    </Container>
  ) : (
    <Spinner />
  );
};

export default TillSelect;
