import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import useAuth from '../hooks/useAuth';
import useHistory from '../hooks/useHistory';
import useEndpoint from '../hooks/useEndpoint';
import useAsyncEndpoint from '../hooks/useAsyncEndpoint';
import useLocalStorage from '../hooks/useLocalStorage';
import logo from '../assets/logo.png';

// cases
// Logged in Branch user - don't show Act as
// Logged in as Area manager - don't show act as
// Logged in as Head office
//   Not currently impersonating anyone - show list of branches that they can impersonate
//   Impersonating branch user - show revert option

// when impersonating someone store old session token in ph_impersonator_session

// TODO: we need to display the impersonator tool if the session has an impersonator

const Impersonator = () => {
  const { session, setSession } = useAuth();
  const history = useHistory();
  const [impersonatorSession, setImpersonatorSession] = useLocalStorage('ph_impersonator_session');

  const [branchUsers, setBranchUsers] = useState([]);
  const [res] = useEndpoint({ method: 'GET', url: 'users/retrieveBranchUsers' });

  const [impersonateRes, impersonate] = useAsyncEndpoint(userId => ({
    method: 'POST',
    url: 'sessions/impersonate',
    data: { userId }
  }));

  useEffect(() => {
    if (res.data) setBranchUsers(res.data);
  }, [res.pending]);
  useEffect(() => {
    if (impersonateRes.data) {
      setImpersonatorSession(session);
      setSession(impersonateRes.data);
      history.push('/');
    }
  }, [impersonateRes.pending]);

  return (
    <NavDropdown title="Impersonate">
      {branchUsers.map(u => (
        <NavDropdown.Item key={u.id} onClick={() => impersonate(u.id)}>
          {u.branchName}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

const TopNav = () => {
  const { session, signOut, setSession } = useAuth();
  const history = useHistory();
  const [impersonatorSession, setImpersonatorSession] = useLocalStorage('ph_impersonator_session');

  const revert = () => {
    setImpersonatorSession(null);
    setSession(impersonatorSession);
    history.push('/');
  };

  return (
    <Navbar
      bg="dark"
      variant="dark"
      sticky="top"
      style={{ height: 60, background: '#37474F', boxShadow: '0 1px 4px #353535' }}
    >
      <Navbar.Brand style={{ letterSpacing: '5px' }}>
        <img src={logo} style={{ height: 28, width: 28, marginRight: '1rem' }} />
        INSIGHT
      </Navbar.Brand>
      <Nav className="ml-auto">
        {session.role === 'HeadOffice' && <Impersonator />}
        <NavDropdown title={<span>{session.email}</span>} style={{ marginLeft: '.5rem' }}>
          {session.assumedBy ? (
            <NavDropdown.Item onClick={revert}>Revert</NavDropdown.Item>
          ) : (
            <NavDropdown.Item onClick={signOut}>Logout</NavDropdown.Item>
          )}
        </NavDropdown>
      </Nav>
    </Navbar>
  );
};

export default TopNav;
