import React from 'react';
import { Card } from 'react-bootstrap';

const Information = ({ tillNo, date }) => (
  <Card>
    <Card.Header>Information</Card.Header>
    <Card.Body>
      <div>
        <strong style={{ marginRight: 6 }}>Date:</strong>
        <span>{date}</span>
      </div>
      <div>
        <strong style={{ marginRight: 6 }}>Till No:</strong>
        <span>{tillNo}</span>
      </div>
    </Card.Body>
  </Card>
);

export default Information;
