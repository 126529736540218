import React, { useState } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { HistoryProvider } from './hooks/useHistory';
import { Container, Card, Form, Button } from 'react-bootstrap';
import useAuth from './hooks/useAuth';

const Login = () => {
  const { signIn } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = e => setEmail(e.target.value);
  const handlePasswordChange = e => setPassword(e.target.value);
  const handleClick = e => {
    e.preventDefault();
    signIn(email, password);
  };
  return (
    <Container fluid>
      <Card style={{ margin: '3rem auto', maxWidth: '40vw' }}>
        <Card.Body>
          <Card.Title style={{ textAlign: 'center' }}>Sign In</Card.Title>

          <Form>
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" onChange={handleEmailChange} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Enter password" onChange={handlePasswordChange} />
            </Form.Group>
            <Button variant="primary" type="submit" onClick={handleClick}>
              Log In
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

const UnauthenticatedApp = () => {
  return (
    <BrowserRouter>
      <HistoryProvider>
        <Switch>
          <Route exact path="/" component={Login} />
          <Redirect to="/" />
        </Switch>
      </HistoryProvider>
    </BrowserRouter>
  );
};

export default UnauthenticatedApp;
