import React from 'react';
import { Table } from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import useBranch from '../useBranch';

const Day = ({ day, label, active, onClick }) => {
  const { saving } = useBranch();
  const handleClick = () => onClick(day, !active);

  return (
    <tr>
      <td>{label}</td>
      <td>
        <Toggle
          active={active}
          on="open"
          off="closed"
          onstyle="success"
          offstyle="danger"
          onClick={handleClick}
          disabled={saving}
        />
      </td>
    </tr>
  );
};

const Days = () => {
  const { branch, updateBranch } = useBranch();

  const getOpen = day => !branch.schedule.daysClosed.includes(day);
  const handleDayClick = (day, active) => {
    if (active) {
      const schedule = { ...branch.schedule, daysClosed: branch.schedule.daysClosed.filter(d => d !== day) };
      updateBranch({ schedule });
    } else {
      const schedule = { ...branch.schedule, daysClosed: [...branch.schedule.daysClosed, day] };
      updateBranch({ schedule });
    }
  };

  return (
    <>
      <strong style={{ marginLeft: '.5rem' }}>Days Closed</strong>
      <Table borderless style={{ width: '10rem', marginTop: '1rem' }}>
        <tbody>
          <Day day="Monday" label="Mon" active={getOpen('Monday')} onClick={handleDayClick} />
          <Day day="Tuesday" label="Tue" active={getOpen('Tuesday')} onClick={handleDayClick} />
          <Day day="Wednesday" label="Wed" active={getOpen('Wednesday')} onClick={handleDayClick} />
          <Day day="Thursday" label="Thu" active={getOpen('Thursday')} onClick={handleDayClick} />
          <Day day="Friday" label="Fri" active={getOpen('Friday')} onClick={handleDayClick} />
          <Day day="Saturday" label="Sat" active={getOpen('Saturday')} onClick={handleDayClick} />
          <Day day="Sunday" label="Sun" active={getOpen('Sunday')} onClick={handleDayClick} />
        </tbody>
      </Table>
    </>
  );
};

export default Days;
