import React from 'react';
import useAuth from './hooks/useAuth';
import BranchApp from './BranchApp';
import HeadOfficeApp from './HeadOfficeApp';
import UnauthenticatedApp from './UnauthenticatedApp';

const App = () => {
  const { session } = useAuth();
  if (!session) {
    return <UnauthenticatedApp />;
  } else if (session.role === 'Branch') {
    return <BranchApp />;
  } else if (session.role === 'HeadOffice') {
    return <HeadOfficeApp />;
  }
};

export default App;
