import React, { useState, useEffect } from 'react';

const DecimalInput = ({ value, onChange }) => {
  const [val, setVal] = useState(value !== undefined ? value.toFixed(2) : '');

  const handleChange = e => {
    if (e.target.value === '') {
      setVal('');
    } else if (parseFloat(e.target.value) < 0) {
      setVal('0');
    } else {
      if (e.target.value.includes('.')) {
        setVal(e.target.value);
      } else {
        setVal(parseFloat(e.target.value).toString());
      }
    }
  };
  const handleFocus = e => {
    if (e.target.value === '') setVal('0');
    setVal(parseFloat(e.target.value).toString());
  };
  const handleBlur = e => {
    const _val = parseFloat(e.target.value);
    if (e.target.value === '') {
      onChange();
    } else if (isNaN(_val)) {
      setVal('0.00');
      onChange(0);
    } else {
      setVal(_val.toFixed(2));
      onChange(_val);
    }
  };

  useEffect(() => {
    setVal(value !== undefined ? value.toFixed(2) : '');
  }, [value]);

  return (
    <input
      className="form-control"
      type="number"
      step="0.01"
      value={val}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};

DecimalInput.defaultProps = {
  onChange: () => {}
};

export default DecimalInput;
