import React from 'react';
import { Container, Row, Col, Breadcrumb, Button, Card, Table } from 'react-bootstrap';
import queryString from 'query-string';
import { FormProvider } from '../../hooks/useForm';
import useHistory from '../../hooks/useHistory';
import Summary from './Summary';
import ETP from './ETP';
import Paper from './Paper';
import MDA from './MDA';
import Submit from './Submit';
import initialValues from './initialValues';

const Session = ({ location }) => {
  const { date } = queryString.parse(location.search);
  const history = useHistory();

  const handleBreadcrumbClick = () => history.push('/prescriptions');

  return (
    <FormProvider formName={`prescriptions_${date}`} initialValues={initialValues}>
      <Container fluid style={{ paddingTop: '1rem' }}>
        <Breadcrumb>
          <Breadcrumb.Item onClick={handleBreadcrumbClick}>NHS</Breadcrumb.Item>
          <Breadcrumb.Item active>NHS Figures</Breadcrumb.Item>
        </Breadcrumb>

        <Summary />

        <Row style={{ paddingBottom: '1rem' }}>
          <Col md={6}>
            <ETP />
          </Col>
          <Col md={6}>
            <Paper />
          </Col>
        </Row>

        <Row style={{ paddingBottom: '1rem' }}>
          <Col md={6}>
            <MDA />
          </Col>
          <Col md={6}>
            <Submit date={date} />
          </Col>
        </Row>
      </Container>
    </FormProvider>
  );
};

export default Session;
