import React, { useLayoutEffect } from 'react';
import Menu, { SubMenu, MenuItem } from 'rc-menu';
import { withRouter } from 'react-router-dom';
import useHistory from '../hooks/useHistory';

// using router figure out which key should be rendered initially
const SideNav = ({ location, navConfig }) => {
  const history = useHistory();
  // find the navConfig entry where location.pathname matches eventKey - this is the current active

  const handleClick = ({ key }) => {
    history.push(key);
  };

  function renderNav(navItems) {
    return navItems.map((navItem, i) =>
      navItem.eventKey ? (
        <MenuItem key={navItem.eventKey}>{navItem.label}</MenuItem>
      ) : (
        <SubMenu key={i} title={navItem.label}>
          {renderNav(navItem.children)}
        </SubMenu>
      )
    );
  }

  return (
    <Menu mode="inline" onClick={handleClick} style={{ height: '100%' }}>
      {renderNav(navConfig)}
    </Menu>
  );
};

export default withRouter(SideNav);
