import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { Table, Nav } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import useBranch from '../useBranch';

const Dates = () => {
  const { branch, updateBranch, saving } = useBranch();
  const dpEl = useRef();
  const [selectingDate, setSelectingDate] = useState(false);

  useEffect(() => {
    if (dpEl.current) dpEl.current.setFocus();
  }, [selectingDate]);

  const togglePicker = () => setSelectingDate(!selectingDate);
  const addDate = dateEl => {
    const date = moment(dateEl).format('DD/MM/YYYY');
    // TODO: check it doesn't already exist
    const schedule = { ...branch.schedule, datesClosed: [...branch.schedule.datesClosed, date] };

    updateBranch({ schedule });
    setSelectingDate(false);
  };
  const removeDate = date => {
    const schedule = { ...branch.schedule, datesClosed: [...branch.schedule.datesClosed.filter(d => d !== date)] };
    updateBranch({ schedule });
  };

  return (
    <>
      <strong style={{ marginLeft: '.5rem' }}>Dates Closed</strong>
      <div style={{ overflowY: 'scroll', maxHeight: '24rem', marginTop: '.5rem' }}>
        <Table style={{ marginTop: '1rem', width: '15rem' }}>
          <tbody>
            {branch.schedule.datesClosed.map((date, i) => {
              const trStyle = branch.schedule.datesClosed.length === i + 1 ? { borderBottom: '1px solid #dee2e6' } : {};
              return (
                <tr key={date} style={trStyle}>
                  <td>{date}</td>
                  <td>
                    <Nav.Link
                      style={{ padding: 0, textAlign: 'right' }}
                      onClick={() => removeDate(date)}
                      disabled={saving}
                    >
                      x
                    </Nav.Link>
                  </td>
                </tr>
              );
            })}

            <tr>
              <td style={{ border: 'none' }}>
                {selectingDate ? (
                  <DatePicker ref={dpEl} style={{ borderWidth: 0 }} onChange={addDate} onClickOutside={togglePicker} />
                ) : (
                  <Nav.Link style={{ padding: 0 }} onClick={togglePicker} disabled={saving}>
                    add date
                  </Nav.Link>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Dates;
