import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import useEndpoint from '../hooks/useEndpoint';
import useAuth from '../hooks/useAuth';
import useHistory from '../hooks/useHistory';
import Spinner from '../components/Spinner';
import Missed from './Missed';
import Session from './Session';
import TillSelect from './TillSelect';

const CashingUp = ({ location, match }) => {
  const [routing, setRouting] = useState(true);
  const history = useHistory();
  const { session } = useAuth();
  const [res, refresh] = useEndpoint({
    method: 'GET',
    url: `cashUpSessions/missedSessions?branchId=${session.branchId}`
  });

  useEffect(() => {
    if (!routing && location.pathname === match.path && res.complete) {
      setRouting(true);
      refresh();
      return;
    }
    if (!res.complete || res.error) return;

    if (location.pathname === `${match.path}/session`) {
    } else if (res.data.length > 0 && location.pathname !== `${match.path}/missed`) {
      history.replace(`${match.path}/missed`);
    } else if (res.data.length === 0 && location.pathname !== `${match.path}/till-select`) {
      history.replace(`${match.path}/till-select`);
    }

    setRouting(false);
  }, [res, location]);

  return routing ? (
    <Spinner />
  ) : (
    <Switch>
      <Route exact path={match.path} component={Spinner} />
      <Route path={`${match.path}/till-select`} component={TillSelect} />
      <Route path={`${match.path}/missed`} component={Missed} />
      <Route path={`${match.path}/session`} component={Session} />
    </Switch>
  );
};

export default CashingUp;
